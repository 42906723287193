import React from 'react';
import {st, classes} from './NoAvailableSlots.st.css';
import {AccessibilityHtmlTags} from '../../../../../utils/AccessibilityHtmlTags.const';
import {Text} from 'wix-ui-tpa/Text';
import {
  NOTIFICATION_TYPE,
  SectionNotification,
} from 'wix-ui-tpa/SectionNotification';
import {TextButton} from 'wix-ui-tpa/TextButton';
import {useEnvironment} from '@wix/yoshi-flow-editor';
import {NoAvailableSlotsViewModel} from '../../../ViewModel/noAvailableSlotsViewModel/noAvailableSlotsViewModel';
import {NoAvailableSlotsDataHooks} from './NoAvailableSlotsDataHooks';
import {useCalendarActions} from '../../../Hooks/useCalendarActions';
import {ReactComponent as AlertIcon} from '../../../../../assets/Icons/notificationAlert.svg';

export type NoAvailableSlotsProps = {
  viewModel: NoAvailableSlotsViewModel;
};

const NoAvailableSlots: React.FC<NoAvailableSlotsProps> = ({viewModel}) => {
  const {isMobile} = useEnvironment();
  const {
    noSessionsOfferedText,
    goToNextAvailableLinkText,
    noUpcomingTimeSlotsText,
  } = viewModel;
  const {goToNextAvailableDate} = useCalendarActions();

  return (
    <div className={st(classes.root, {isMobile})}>
      <div
        data-hook={NoAvailableSlotsDataHooks.NO_AVAILABLE_SLOTS_WRAPPER}
        className={st(classes.noAvailableSlotsWrapper)}
      >
        <Text
          data-hook={NoAvailableSlotsDataHooks.NO_AVAILABLE_SLOTS}
          className={st(classes.noAvailableSlots)}
          tagName={AccessibilityHtmlTags.Paragraph}
        >
          {noSessionsOfferedText}
        </Text>
        {goToNextAvailableLinkText ? (
          <TextButton
            data-hook={NoAvailableSlotsDataHooks.NO_AVAILABLE_SLOTS_LINK}
            className={classes.nextAvailableDateLink}
            onClick={() => goToNextAvailableDate()}
          >
            {goToNextAvailableLinkText}
          </TextButton>
        ) : null}
        {noUpcomingTimeSlotsText ? (
          <SectionNotification
            className={classes.notification}
            data-hook={
              NoAvailableSlotsDataHooks.NO_AVAILABLE_SLOTS_NOTIFICATION
            }
            type={NOTIFICATION_TYPE.alert}
          >
            <SectionNotification.Icon icon={<AlertIcon/>}/>
            <SectionNotification.Text>
              {noUpcomingTimeSlotsText}
            </SectionNotification.Text>
          </SectionNotification>
        ) : null}
      </div>
    </div>
  );
};

export default NoAvailableSlots;
